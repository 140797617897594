/*@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
*/

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply text-black bg-white;
}

.layout-height {
  @apply max-h-[calc(100vh-64px)] 2xl:max-h-[calc(100vh-108px)];
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&family=Poppins:wght@400;600;700&display=swap");
* {
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif !important;
  margin: 0 !important;
  font-size: 16px !important;
  color: #4e5562 !important;
  background-color: #fff !important;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
div {
  font-family: "Poppins", sans-serif !important;
}
a {
  cursor: pointer !important;
  text-decoration: none !important;
  color: #fff;
}
ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.app_body {
  min-height: 90vh;
  padding: 100px 0 0 0;
}

svg text {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
svg text::selection {
  background: none;
}

.no-display {
  display: none !important;
}

.no-padding {
  padding: 0 !important;
}
p {
  margin: 0;
}
input::placeholder {
  color: #495057 !important;
  font-size: 14px !important;
  opacity: 0.7;
}
.mt-0 {
  margin-top: 0 !important;
}
.auto--height {
  height: auto !important;
  min-height: 10px !important;
}
.input--error {
  border: 1px solid #bd362f !important;
}
.mt--5 {
  margin: 5px 0 0 0;
}
.mt--10 {
  margin: 10px 0 0 0;
}
.mt--15 {
  margin: 15px 0 0 0;
}
.mt--20 {
  margin: 20px 0 0 0;
}
.mt--25 {
  margin: 25px 0 0 0;
}
.mt--30 {
  margin: 30px 0 0 0;
}
.mt--35 {
  margin: 35px 0 0 0;
}
.mt--40 {
  margin: 40px 0 0 0;
}
.mt--45 {
  margin: 45px 0 0 0;
}
.mt--50 {
  margin: 50px 0 0 0;
}
input {
  outline: none !important;
}
/******MainBody Tags Ends******/

/*ECG Starts*/
.header {
  display: flex;
  width: 100%;
  height: 106px;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  justify-content: space-between;
}
.header img {
  width: auto;
  height: auto;
}
.holter-logo {
  height: 85px !important;
}
.btn--left__menu {
  position: fixed;
  left: 25px;
  top: 44px;
  padding: 0 !important;
  border-radius: 0 !important;
}
.pbtn--left__menu {
  top: 40px;
}
.btn--left__menu:hover {
  background: none !important;
}
.header {
  display: flex;
  width: 100%;
  height: 106px;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  justify-content: space-between;
  padding: 0 25px 0 25px;
}
.header--left {
  display: flex;
  align-items: center;
  gap: 50px;
}
.patient--data {
  display: inline-block;
}
.p--name {
  font-weight: 700;
  color: rgb(118, 195, 255);
  font-size: 24px;
  display: flex;
  font-family: "Poppins" !important;
  line-height: 36px;
}
.p--data {
  font-weight: 600;
  color: #000;
  font-size: 16px;
  display: flex;
  font-family: "Poppins" !important;
  line-height: 24px;
  gap: 7px;
  align-items: center;
}
.p--data img {
  width: 16px !important;
  height: 18px !important;
}
.patient--data .p--heart {
  width: 35px;
  height: 30px;
  display: inline-block;
  margin: -4px 0 0 10px;
}
.patient--data img {
  width: 35px;
}
.header--right {
  display: flex;
  align-items: center;
  gap: 24px;
}
.icon--notify {
  display: flex;
  align-items: center;
  gap: 15px;
}
.dia--name {
  font-weight: 700;
  color: #000;
  font-size: 16px;
  display: flex;
  font-family: "Inter" !important;
  line-height: 20px;
}
.dia--data {
  background: #ffd2d2;
  color: #000;
  font-weight: 700;
  font-size: 14px;
  padding: 0px 17px;
  border-radius: 25px;
  margin: 0 0 0 0;
  height: 30px;
  display: flex;
  align-items: center;
  font-family: "Inter" !important;
  line-height: 20px;
}
.dia-data-green {
  background: #b5ffb1;
}
.rotate-180 {
  transform: rotate(180deg);
}
.height--20 {
  height: 20px !important;
}
.ecg--main {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}
.ecg--left {
  min-width: 255px;
  max-width: 255px;
  padding: 12px 0 0 0;
  height: calc(100vh - 106px);
  overflow: scroll;
  display: inline-block;
}
.ecg--left a {
  color: #000 !important;
  display: flex;
  align-items: center;
  padding: 7px 11px 7px 16px;
  font-size: 14px;
  font-weight: 700;
  justify-content: space-between;
  margin: 0 0 0px 0;
  border-radius: 0 30px 30px 0px;
}
.ecg--left a:hover {
  background: #ebff00;
}
.a--count {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.a--count--sm {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  color: #000;
  font-weight: 500;
}
.observation {
  font-size: 12px;
  padding: 0 0 0 25px;
}
.ecg--left__measure {
  display: flex;
  padding: 0 0px 5px 16px;
  margin: 10px 0 15px 0;
  border-bottom: 1px solid rgba(196, 196, 196, 0.2);
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #76c3ff;
}
.ecg--left__dimens {
  background: #daefff;
  border-radius: 8px;
  padding: 15px 15px 15px 30px;
  width: calc(100% - 30px);
  margin: 0 0 0 30px;
  position: relative;
}
.ecg--left__dimens__inn {
  display: inline-block;
  width: 100%;
  height: 105px;
  overflow: auto;
}
.ecg--left__dimens span {
  display: flex;
  color: #000;
  font-weight: 700;
  font-size: 10px;
  margin: 0 0 15px 0;
}
.ecg--left__dimens span:last-child {
  margin: 0 0 5px 0;
}
.ecg--right {
  width: 100%;
}
.ecg--right__top {
  display: flex;
  align-items: center;
  height: 64px;
  background: #767475;
  padding: 10px;
}
.ecg--right__top__strip {
  display: flex;
  background: #f5f5f5;
  padding: 0 25px;
  height: 30px;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 700;
  color: #000;
}
.ecg--right__top__strip a {
  display: flex;
}
.ecg--right__top__controls {
  display: flex;
  height: 44px;
  align-items: center;
  padding: 0 0 0 15px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
}
.ecg--right__top__controls__main {
  justify-content: space-between;
}
.ecg--right__top__controls__left {
  display: flex;
  align-items: center;
  height: 44px;
}
.ecg--right__top__controls__right {
  display: flex;
  align-items: center;
  gap: 0;
  padding: 0 20px 0 0;
  height: 44px;
}
.ecg--right__top__controls__right a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  gap: 10px;
  color: #000 !important;
}
.ecg--split__view {
  display: inline-block;
  width: 100%;
  height: 200px;
  overflow: hidden;
}
.ecg--back {
  background-color: #fff;
  height: 37px;
}
.controls--pt1 {
  display: flex;
  align-items: center;
  gap: 2px;
  height: 44px;
  position: relative;
  padding-top: 5px;
}
.controls--pt1 a {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
}
/*.controls--pt1 a:hover {
  background: #daefff;
}*/
.control--open {
  background: #daefff;
}
.opacity-disable {
  opacity: 0.5 !important;
  cursor: no-drop !important;
}
.controls--pt1 .animation {
  position: absolute;
  top: -10px;
  z-index: 0;
  transition: left 0.3s linear;
  width: 20px;
  height: 20px;
  background: #767475;
  border-radius: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.animation.start-home span {
  display: inline-block;
  background: #76c4ff;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
}
.e-norm {
  display: inline-block;
}
.e-sele {
  display: none;
}
.control--open1 .e-norm,
.control--open2 .e-norm,
.control--open3 .e-norm,
.control--open4 .e-norm {
  display: none;
}
.control--open1 .e-sele,
.control--open2 .e-sele,
.control--open3 .e-sele,
.control--open4 .e-sele {
  display: inline-block;
}
/*.controls--pt1 .start-home,
.controls--pt1 a:nth-child(1):hover ~ .animation {
  left: 0;
  width: 50px;
  transition: left 0.5s linear;
}*/
.controls--pt1 .start-home,
.controls--pt1 a.control--open1 ~ .animation {
  left: 5px;
}
/*.controls--pt1 .start-home,
.controls--pt1 a:nth-child(2):hover ~ .animation {
  left: 50px;
  width: 50px;
  transition: left 0.5s linear;
}*/
.controls--pt1 .start-home,
.controls--pt1 a.control--open2 ~ .animation {
  left: 40px;
}
/*.controls--pt1 .start-home,
.controls--pt1 a:nth-child(3):hover ~ .animation {
  left: 100px;
  width: 50px;
  transition: left 0.5s linear;
}*/
.controls--pt1 .start-home,
.controls--pt1 a.control--open3 ~ .animation {
  left: 73px;
}
/*.controls--pt1 .start-home,
.controls--pt1 a:nth-child(4):hover ~ .animation {
  left: 150px;
  width: 50px;
  transition: left 0.5s linear;
}*/
.controls--pt1 .start-home,
.controls--pt1 a.control--open4 ~ .animation {
  left: 110px;
}
.controls--pt1 a img {
  height: 18px;
  width: auto;
  color: transparent;
}
.controls--pt1 hr {
  height: 25px;
  width: 0.5px;
  border: none;
  background: #999faa;
  margin: 0 0 0 5px;
}
.controls--pt2 {
  display: flex;
  align-items: center;
  padding: 0 3px;
  gap: 20px;
  margin: 0 0 0 0;
  padding-top: 5px;
}
.controls--pt2__ddl {
  display: flex;
  align-items: center;
  position: relative;
  padding: 6px 8px;
  border-radius: 3px;
}
.controls--pt2__ddl:hover {
  background: #daefff;
}
.controls--pt2__ddl a {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 13px;
  font-weight: 600;
  gap: 0;
}
.controls--pt2__ddl a span {
  white-space: nowrap;
}
.controls--pt2__ddl a img {
  height: 8px;
  width: auto;
  display: flex;
  margin: 0 0 0 10px;
}
.controls--pt2__ddl a img.ddl-calendar {
  width: 18px;
  height: 18px;
  margin: 0;
}
.controls--pt2__ddl__drop {
  position: absolute;
  background: #fff;
  min-width: 150px;
  left: 0px;
  top: 33px;
  box-shadow: 0 0 3px 0px #c9cfda;
  border-radius: 3px;
  padding: 10px 0;
  max-height: 600px;
  overflow: auto;
  z-index: 501;
}
.controls--pt2__ddl__drop a {
  display: flex;
  padding: 0 8px;
  align-items: center;
  height: 35px;
  color: #616161;
  font-size: 12px;
}
.controls--pt2__ddl__drop a:hover {
  background: #daefff;
}
.selected-Channel {
  background: #daefff;
}
.controls--pt3 {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 0 20px 0 0px;
  padding-top: 5px;
}
.controls--pt3 input {
  border: 1px solid #000;
  width: 74px;
  height: 22px;
  font-size: 13px;
  color: #000;
  font-weight: 400;
  text-align: center;
}
.controls--pt3 input::placeholder {
  font-size: 13px;
  color: #000 !important;
  font-weight: 400;
}
.controls--pt3 a {
  display: flex;
}
.controls--pt3 a img {
  height: 14px;
  width: auto;
}
.ecg--right__bottom {
  display: flex;
  width: 100%;
  padding: 0;
  height: calc(100vh - 430px); /*190*/
  /* background: aliceblue;*/
  position: relative;
  overflow: hidden;
}
.ecg--right__bottom__in {
  display: inline-block;
  width: calc(100% - 25px);
}
.ecg--right__scroll {
  display: inline-block;
  width: 25px;
  background: #777;
  position: relative;
}
.ecg--right__scroll a {
  background: #555;
  display: flex;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  /*z-index: 1000;*/
  left: 0;
  color: #fff;
}
.ecg--right__scroll a.scroll-1 {
  top: 0;
}
.ecg--right__scroll a.scroll-2 {
  position: absolute;
  bottom: 53px;
}
.ecg--right__scroll a.scroll-3 {
  position: absolute;
  bottom: 26px;
}
.ecg--right__scroll a.scroll-4 {
  position: absolute;
  bottom: 0;
}
.ecg--right__bottom.ecg--right__bottom__sm {
  height: calc(100vh - 470px);
}
.ecg--main--sm .ecg--right__bottom.ecg--right__bottom__sm {
  height: calc(100vh - 712px);
}
.ecg--right__bottom.ecg--right__bottom__sm__beat {
  height: calc(100vh - 680px);
}
.ecg--main--sm .ecg--right__bottom.ecg--right__bottom__sm__beat {
  height: calc(100vh - 712px);
}
.ecg--right__top__pop {
  display: inline-block;
  width: 100%;
  padding: 10px 10px 10px 0;
  height: 238px;
}
.ecg--right__top__pop .ecg--trends__right {
  width: 100%;
}
.ecg--right__bottom__pop {
  display: inline-block;
  width: 100%;
  padding: 10px 10px 10px 0;
  height: 238px;
}
.ecg--right__bottom__pop2 {
  display: flex;
  width: auto;
  background: #daefff;
  height: calc(100% - 106px);
  position: fixed;
  right: -5000px;
  top: 106px;
  z-index: 500;
  transition: right 0.3s linear;
}
.ecg--right__bottom__pop3 {
  display: inline-block;
  width: 100%;
  padding: 0;
  height: 238px;
}
.ecg--right__bottom__over {
  position: absolute;
  left: 10px;
  top: 10px;
  /*overflow: auto;*/
  max-height: calc(100% - 20px);
  min-width: 450px;
}
.ecg--right__bottom__over span {
  display: flex;
  font-weight: 500;
  position: relative;
  cursor: pointer;
  background: #fff;
  padding: 7px 10px;
}
.ecg--right__bottom__over span:last-child {
  margin: 0 0 0 0;
}
.del--clear {
  position: absolute;
  background: #fff;
  padding: 5px 0;
  box-shadow: 0 0px 5px 0px #ccc;
  border-radius: 5px;
  width: 100px;
  right: 0px;
  top: 25px;
  z-index: 500;
}
.del--clear.del--clear--2 {
  right: 15px;
  top: 15px;
}
.del--clear.del--clear--2 a {
  margin: 0 !important;
  font-weight: 500;
  font-size: 13px;
  height: 25px;
}
.ecg--right__bottom__over:empty {
  display: none;
}
.del--clear a {
  color: #4e5562 !important;
  display: flex;
  padding: 0 15px;
  font-size: 14px;
  align-items: center;
  height: 30px;
}
.del--clear a:hover {
  background: #daefff;
}
.ecg--trends {
  display: flex;
}
.ecg--trends__left a {
  display: flex;
  min-width: 40px;
  justify-content: center;
  align-items: center;
  height: 35px;
  color: #000;
  border-radius: 5px 0 0 5px;
  font-size: 13px;
}
.ecg--trends__left a:hover {
  background: #d2d2d2;
}
.ecg--trends__left a.trends--selected {
  background: #d2d2d2;
}
.ecg--trends__right {
  display: flex;
  width: calc(100% - 40px);
  border: 2px solid #c7cace;
  height: 230px;
  padding: 10px;
}
.sm-height {
  padding-top: 0 !important;
}
.ecg--popup {
  display: inline-block;
  width: 100%;
  padding: 10px 20px 15px 20px;
}
.ecg--popup__title {
  color: #000;
  font-size: 20px;
  line-height: 29px;
  font-weight: 700;
}
.ecg--checkboxes {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 15px 0 0 0;
  row-gap: 15px;
}
.input--radio.check--btn {
  display: flex;
  align-items: center;
  padding: 0 0 0 35px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #000;
}
.input--radio.radio--btn {
  display: flex;
  align-items: center;
  padding: 0 0 0 35px;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #a9a6a6;
}
.report--selected .input--radio.radio--btn {
  color: #000;
}
.ecg--checkboxes__report {
  display: inline-block;
  width: 100%;
  margin: 25px 0 0 0;
}
.report--span {
  display: inline-block;
  width: 100%;
  border-bottom: 1px dashed #000;
  margin: 10px 0 0 20px;
  max-width: 500px;
}
.report--radio {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 15px;
  margin: 15px 0 15px 0;
  padding: 0 0 0 57px;
}
.modal--button.del--modal__btn {
  display: flex;
  align-items: center;
  gap: 23px;
  justify-content: center;
  margin: 20px 0 0 0;
}
.del--modal__btn a {
  width: 150px;
  height: 40px;
  box-shadow: 0px 20px 40px rgba(153, 153, 153, 0.25);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 24px;
  color: #fff !important;
  font-weight: 600;
}
.del--modal__btn a:first-child {
  background: #87ce83;
}
.del--modal__btn a:last-child {
  background: #e36f6f;
}
.ecg--templates {
  display: flex;
  gap: 0;
  padding: 0 12px 0 0;
}
.ecg--templates--left {
  /*height: 225px;
  overflow-y: scroll;*/
  width: 440px;
  padding: 12px 0px 12px 12px;
}
.ecg--templates--right {
  /*height: 225px;
  overflow-y: scroll;*/
  width: 460px;
  background: #e6f3fc;
  padding: 12px;
  position: relative;
}
.expand--right {
  width: 550px;
}
.template--close__head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #dfe2e9;
  height: 46px;
  padding: 0 10px;
  margin: 0 0 15px 0;
}
.template--selection {
  display: flex;
  align-items: center;
  gap: 10px;
}
.template--selection a {
  display: flex;
  width: 60px;
  height: 35px;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc;
  font-size: 13px;
  font-weight: bold;
  color: #acacac !important;
}
.template--selection a.accord--show {
  background: #fff;
  border: 1px solid #fff;
  color: #000;
}
.template--selection__view a {
  width: 110px;
  background: #daefff;
  border: 1px solid #fff;
  color: #000 !important;
}
.templates--left--inn {
  float: left;
  width: 100%;
  padding: 0 0 0 0;
}
.templates--left--head {
  display: flex;
  background: #c11401;
  padding: 10px;
  color: #000;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  position: relative;
  margin: 0 0 5px 0;
  cursor: pointer;
}
.templates--left--head {
  display: flex;
  background: #fff;
  color: #000;
  justify-content: space-between;
  align-items: center;
  height: 61px;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 16px;
  padding: 0 12px 0 24px;
  margin: 0 0 47px 0;
}
.template--Title {
  display: flex;
  align-items: center;
  gap: 30px;
}
.type-count {
  display: flex;
  width: 35px;
  height: 35px;
  background: #daefff;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  line-height: 30px;
  color: #000;
  font-weight: 600;
}
.type-name {
  font-size: 20px;
  line-height: 30px;
  font-weight: 600;
  color: #000;
}
.blue-color {
  color: rgba(52, 166, 254, 1);
}
.template--accord {
  display: flex;
  width: 35px;
  height: 35px;
  align-items: center;
  justify-content: center;
  background: rgb(218, 239, 255);
  border-radius: 100%;
}
.template--accord img {
  width: 14px;
  height: auto;
  display: flex;
}
.templates--left--head--yellow {
  background: #f79a00;
}
.template--classes {
  float: left;
  width: 100%;
  overflow-y: auto;
  height: calc(100vh - 180px);
}
.template--class--item {
  float: left;
  background: #fff;
  min-width: 100%;
  min-height: 75px;
  margin: 0 0px 15px 0;
  border: 2px solid #fff;
}
.strip--main-no-shadow {
  display: inline-block;
  width: 100%;
}
.strip--main-no-shadow.strip--main-shadow {
  background: #fff;
  box-shadow: 0px 7px 29px rgba(100, 100, 111, 0.2);
  border-radius: 16px;
  margin: 0 0 47px 0;
  padding: 0 12px 20px 24px;
}
.strip--main-no-shadow.strip--main-shadow .templates--left--head {
  box-shadow: none;
  margin: 0 0 40px 0;
  padding: 0;
}
.template--class--item__selected {
  border: 2px solid #c11501;
}
.template--class--item.SelectedView {
  background: #6dcab7;
}
.template--class--item:hover {
  background: #f5f5f5;
}
.beats--views {
  position: absolute;
  right: 12px;
}
.beats--views__inn {
  display: flex;
  position: relative;
  height: 44px;
  align-items: center;
}
.beats--view__open {
  display: flex;
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 30px;
  margin: 0 10px 0 0;
}
.beats--view__open:hover {
  color: #fff;
}
.beats--view__drop {
  position: absolute;
  right: 10px;
  background: #fff;
  font-weight: normal;
  min-width: 150px;
  padding: 5px 0px;
  top: 40px;
  border-radius: 5px;
  box-shadow: 0 0px 5px rgba(0, 0, 0, 0.3);
}
.beats--view__drop a {
  display: flex;
  padding: 0 10px;
  font-size: 13px;
  color: #444 !important;
  height: 25px;
  align-items: center;
}
.beats--view__drop a:hover {
  background: #f5f5f5;
}
.beats--view__drop a.SelectedView {
  background: #f5f5f5;
}
.beats--view__drop hr {
  margin: 5px 0px;
  opacity: 0.1;
}
/*ECG Ends*/

/*Stats Start*/
.ecg--stats {
  display: inline-block;
  width: 100%;
  padding: 0 0 0 30px;
}
.ecg--stats__row {
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 1fr;
  font-size: 13px;
  column-gap: 5px;
  align-items: flex-end;
  margin: 0 0 10px 0;
}
.stats--name {
  margin: 0 0 2px 0;
}
.txt--min {
  display: flex;
  justify-content: flex-end;
  padding: 0 5px 0 0;
  color: #ccc;
  font-size: 11px;
  margin: 0 0 2px 0;
}
.txt--min2 {
  display: flex;
  justify-content: flex-end;
  background: #daefff;
  padding: 3px 5px 3px 0;
}
.stats--bpm {
  margin: 0 0 2px 0;
  font-size: 10px;
}
.stats--sec {
  margin: 0 0 2px 0;
  font-size: 11px;
}
/*Stats End*/

/*Strips Panel Starts*/
.ecg--strips__panel {
  display: inline-block;
  position: fixed;
  right: -1000px;
  top: 106px;
  width: 500px;
  height: calc(100% - 106px);
  background: #0d539d;
  z-index: 2000;
  transition: right 0.3s linear;
  padding: 20px;
  box-shadow: 0 0px 10px 0px rgba(0, 0, 0, 0.1);
  overflow: auto;
}
.ecg--strips__panel__open {
  right: 0;
  transition: right 0.3s linear;
}
.ecg--strips__close {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 20px 0;
}
.ecg--strips__close__inn {
  display: flex;
  align-items: center;
  gap: 25px;
  padding: 0 0 0 24px;
}
.stripstxt {
  color: #000;
  font-weight: 600;
  font-size: 20px;
  line-height: 30px;
  display: flex;
}
.stripstxt:first-child {
  background: rgb(0, 96, 171);
  color: #fff;
  width: 35px;
  height: 35px;
  border-radius: 100%;
  align-items: center;
  justify-content: center;
}
.strips--add__report {
  background: rgb(84, 209, 77);
  display: flex;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  padding: 0 15px 0 20px;
  border-radius: 5px;
  line-height: 24px;
  width: 202px;
  height: 42px;
  align-items: center;
  justify-content: space-between;
}
.strips--add__report:hover {
  color: #fff;
}
.strips--close {
  background: #fff;
  display: flex;
  height: 29px;
  width: 29px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.template--close {
  background: #fff;
  display: flex;
  height: 20px;
  width: 20px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}
.strips--accordion {
  display: inline-block;
  width: 100%;
  height: calc(100vh - 370px);
}
.ecg--strips__panel .templates--left--head {
  background: #daefff;
  margin: 30px 0 0 0;
  color: #000;
}
.ecg--strips__panel .templates--left--head:first-child {
  margin: 0;
}
.ecg--strips__panel .template--accord {
  border: 1px solid #000;
  color: #000;
}
.ecg--strips__panel .template--accord:hover {
  border: 1px solid #000;
  color: #000;
}
.single--strip {
  display: inline-block;
  width: 458px;
  margin: 10px 10px 10px 0;
  padding: 0 10px;
}
.strip--head {
  display: flex;
  background: #f5f5f5;
  padding: 6px 10px;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
  color: #000;
  border-bottom: 1px solid #ccc;
}
.btn--prenex {
  padding: 0;
  background: none;
  border: none;
  margin: 0 5px;
}
.strip--data img {
  width: 100%;
  height: auto;
}
.strip--data {
  background: #fff;
  padding: 0 0;
}
/*Strips Panel Ends*/

/**App Modals Starts**/
.modal-dialog {
  max-width: 430px !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  align-items: center;
  display: flex;
  height: 100%;
}
.modal-content {
  background: #fff !important;
  border-radius: 12px !important;
  color: #000 !important;
  font-family: "Poppins", sans-serif !important;
}
.modal-header {
  padding: 0 25px !important;
  border: none !important;
  /*height: 60px !important;*/
}
.modal-header img {
  margin: -5px 10px 0 0;
}
.modal-title.h4 {
  font-size: 20px !important;
  font-weight: 700 !important;
}
.modal-header .btn-close {
  padding: 0 !important;
  font-size: 20px !important;
  margin: 0 !important;
  display: none !important;
}
.modal-body {
  padding: 0 !important;
}
.Error--Modal .modal-dialog {
  min-width: 200px;
}
.error-message {
  display: inline-block;
  width: 100%;
  padding: 20px;
  font-size: 14px;
}
.Error--Modal .modal-header {
  padding: 20px 20px 0 20px !important;
}
/**App Modals Ends**/

/**Radio Starts**/
/* The radio--btn */
.radio--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.radio--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.radio--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: none;
  border-radius: 50%;
  border: 0.86px solid #a9a6a6;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* Create a custom radio button */
.report--selected .radio--mark {
  border: 0.86px solid #000;
}

/* On mouse-over, add a grey background color */
.radio--btn:hover input ~ .radio--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.radio--btn input:checked ~ .radio--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio--btn input:checked ~ .radio--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.radio--btn .radio--mark:after {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #a9a6a6;
}
.report--selected .radio--btn .radio--mark:after {
  background: #000;
}
/**Radio Ends**/

/**Checkbox Starts**/
.check--btn {
  position: relative;
}

/* Hide the browser's default radio button */
.check--btn input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 25px;
  height: 25px;
  left: 0;
  top: 0;
  z-index: 100;
}

/* Create a custom radio button */
.check--mark {
  position: absolute;
  top: 0;
  left: 0;
  height: 23px;
  width: 23px;
  background-color: none;
  border-radius: 5px;
  border: 1.16px solid #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* On mouse-over, add a grey background color */
.check--btn:hover input ~ .check--mark {
  background-color: none;
}

/* When the radio button is checked, add a blue background */
.check--btn input:checked ~ .check--mark {
  background-color: none;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.check--mark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.check--btn input:checked ~ .check--mark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.check--btn .check--mark:after {
  width: 6px;
  height: 11px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 3px;
}
/**Checkbox Ends**/
/**Transparent Scroll Starts**/
.custom--scroll {
  scrollbar-color: #fff Transparent;
  scrollbar-width: thin;
}
.custom--scroll::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.custom--scroll::-webkit-scrollbar-thumb {
  background: #fff;
  border-radius: 0;
}
.custom--scroll::-webkit-scrollbar-thumb:hover {
  background: #fff;
}
/**Transparent Scroll Ends**/

/*CustomScroll Starts*/
.custom--scroll2 {
  scrollbar-color: #bbb Transparent;
  scrollbar-width: thin;
}
.custom--scroll2::-webkit-scrollbar {
  width: 7px !important;
  height: 7px;
  border-radius: 0;
}
.custom--scroll2::-webkit-scrollbar-track {
  border-radius: 0;
  background: transparent;
}
.custom--scroll2::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 0;
}
.custom--scroll2::-webkit-scrollbar-thumb:hover {
  background: #bbb;
}
/*CustomScroll Ends*/

/*Temp Divs*/
.ecg--right__buttons {
  display: flex;
  align-items: center;
  gap: 10px;
}
.ecg--right__a {
  font-size: 30px;
  margin: 0 0 0 0;
  color: #000;
  font-weight: 700;
}
.btn--save {
  background: #87ce83;
  color: #fff;
  width: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  font-weight: 500;
  display: flex;
}
.btn--save:hover {
  color: #fff;
}
.btn--close {
  background: #bbb;
  color: #fff;
  width: 65px;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  height: 30px;
  font-weight: 500;
  display: flex;
}
.btn--close:hover {
  color: #fff;
}
/*Temp Divs*/
/*Progress Bar Starts*/
.progress-run {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  background: #989898;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 100000;
}
.progress-run .progress {
  width: 25%;
  height: 60px;
  border-radius: 0;
  background-color: transparent;
  border: 1px solid #353535;
}
.progress-run .progress-bar {
  background-color: #353535;
}
/*Progress Bar Ends*/

/*May Design Updates Starts*/
.menu-top-tabs {
  display: flex;
  align-items: center;
  height: 40px;
  background: #f8f8f8;
  border-radius: 50px;
  padding: 0 0 0 0;
  gap: 5px;
  font-family: "Inter" !important;
}
.menu-top-tabs span {
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  margin: 0 20px 0 0;
}
.menu-top-tabs a {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #000000;
  height: 40px;
  padding: 0 15px;
}
.menu-top-tabs a.selected,
.menu-top-tabs a:hover {
  background: #daefff;
  border-radius: 50px;
}
.a--txt {
  font-family: "Poppins" !important;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #000;
}
.a--count {
  font-family: "Poppins" !important;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #000;
}
.name-count {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: "Poppins" !important;
  padding: 0 11px 0 16px;
  height: 42px;
  cursor: pointer;
  color: #000 !important;
}
.h-l-1 {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
.h-l-2 {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.accordion-main ul {
  padding: 0 0 0 0;
  margin: 5px 0 0 22px;
  padding: 0 0 0 8px;
  border-left: 1px solid #76c3ff;
}
.accordion-main ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 400;
  font-size: 13px;
  line-height: 32px;
  padding: 0 11px 0 0;
  color: #000;
  cursor: pointer;
}
.accordion-main ul li span {
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
}
.accordion-main {
  color: #000;
}
.color-1 {
  color: #ffc452;
}
.color-2 {
  color: #54d14d;
}
.color-3 {
  color: #8d9900;
}
.color-4 {
  color: #db303f;
}
.color-5 {
  color: #0084d1;
}
.color-6 {
  color: #005597;
}
.font-400 {
  font-weight: 400 !important;
}
.name-count.selected,
.name-count:hover {
  border-radius: 0 25px 25px 0;
  color: #000 !important;
  font-weight: 600px !important;
}

.name-count.selected,
.name-count:hover {
  background: #bae1ff;
}
.name-count.color-1.selected,
.name-count.color-1:hover {
  background: #ffe4af;
}
.name-count.color-2.selected,
.name-count.color-2:hover {
  background: #b5ffb1;
}
.name-count.color-3.selected,
.name-count.color-3:hover {
  background: #f6fda1;
}
.name-count.color-4.selected,
.name-count.color-4:hover {
  background: #ffced2;
}
.name-count.color-5.selected,
.name-count.color-5:hover {
  background: #dbbaff;
}
.name-count.color-8.selected,
.name-count.color-8:hover {
  background: #a9fbdd;
}
.name-count.color-9.selected,
.name-count.color-9:hover {
  background: #f4b5ff;
}
.name-count.color-12.selected,
.name-count.color-12:hover {
  background: #dbbaff;
}
.name-count.color-13.selected,
.name-count.color-13:hover {
  background: #ff6961;
}

.name-count.selected .h-l-1.font-400 {
  font-weight: 600 !important;
}
.name-count:hover .h-l-1.font-400 {
  font-weight: 600 !important;
}
.clinic-details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f5f5f5;
  padding: 0 10px;
  height: 50px;
}
.cl-left {
  display: flex;
  align-items: center;
  gap: 8px;
}
.cl-right {
  display: flex;
  align-items: center;
  gap: 15px;
}
.info-detail {
  display: flex;
  align-items: center;
  gap: 10px;
  font-family: "Poppins" !important;
  font-size: 15px;
  line-height: 24px;
  color: #000;
  font-weight: 400;
}
.c-text {
  display: flex;
  background: #fff;
  padding: 0 0;
  font-weight: 600;
  color: #76c3ff;
  width: 152px;
  justify-content: center;
}
.histogram {
  display: inline-block;
  width: 100%;
  border: 0.5px solid #c7cace;
  text-align: center;
  padding: 0 0 0 44px;
  position: relative;
}
.histogram .ecg--trends__right {
  height: 160px;
  border: none;
  width: 100%;
}
.histogram img {
  max-width: 100%;
  height: 110px;
  width: 97%;
}
.histo-hr {
  position: absolute;
  font-size: 10px;
  font-weight: 400;
  top: 46%;
  left: 24px;
}

/*Events Start*/
.Events--main {
  display: inline-block;
  width: 100%;
  padding: 0;
}
.Events--main:first-child {
  padding-bottom: 0;
}
.ecg--right__bottom.ecg--right__bottom__sm__events {
  height: auto;
  position: unset !important;
}
.Events--inn {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 4px;
  background: #fff;
  border: 0.5px solid #c7cace;
  padding: 5px 24px;
  height: auto;
  overflow-y: auto;
  max-height: 300px;
  overflow-x: hidden;
  width: 100%;
}
.Events--box {
  display: flex;
  border: 1px solid #000;
  position: relative;
  cursor: pointer;
}
.Events--box.SelectedBox {
  border: 1px solid rgb(194, 0, 0);
  box-shadow: 0 0 0 1px rgb(194, 0, 0);
}
.Events--box--num {
  display: flex;
  color: rgb(244, 91, 91);
  min-width: 50px;
  height: 17px;
  background: #f5f5f5;
  right: 4px;
  bottom: 4px;
  position: absolute;
  font-size: 12px;
  line-height: 18px;
  justify-content: center;
  align-items: center;
  padding: 0 5px;
}
.Events--top--main {
  display: inline-block;
  width: 100%;
  background: #f5f5f5;
}
.Events--main.Events--inn--graph .Events--inn {
  height: auto;
  overflow: visible;
  display: inline-block;
  width: 100%;
  font-size: 10px;
  font-weight: 400;
  line-height: 15px;
}
.Channel-left {
  display: grid;
  grid-template-columns: 1fr;
  min-width: 35px;
}
.Channel-left span {
  height: 60px;
}
.Channel-left span:first-child {
  height: 26px;
}
.Channel-right {
  height: 206px;
  border: 1px solid #ccc;
  width: 100%;
}
.channel-graph {
  display: inline-block;
  width: 100%;
}
.hr-graph {
  display: flex;
  height: 36px;
  align-items: center;
  margin: 10px 0 0 0;
}
.hr-graph-left {
  display: flex;
  min-width: 35px;
  height: 36px;
  align-items: center;
}
.hr-graph-right {
  display: flex;
  align-items: center;
  height: 36px;
  width: 100%;
}
.hr-graph-right-mins {
  display: flex;
  border: 1px solid #ccc;
  height: 36px;
  width: 100%;
}
.hr-graph-right-btns {
  display: flex;
  gap: 2px;
  margin: 0px 0 0 15px;
}
.hr-graph-right-btns a {
  display: flex;
  background: #dfe2e9;
  height: 36px;
  width: 20px;
  border-radius: 4px;
  align-items: center;
  justify-content: center;
}
.Channel-graph--inn {
  display: flex;
}
.toggle-btns {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  font-weight: 400;
  font-family: "Inter";
  color: #c4c4c4;
  margin: 0 0 0 20px;
}
.toggle-btns a {
  display: flex;
  position: relative;
  width: 39px;
  height: 18px;
  border: 1px solid #76c3ff;
  border-radius: 50px;
  margin: 0 10px;
  align-items: center;
  background: #fff;
}
.toggle-ball {
  position: absolute;
  display: flex;
  width: 14px;
  height: 14px;
  background: #76c3ff;
  border-radius: 100%;
  left: 2px;
}
.toggle-ball.move-to-right {
  left: auto;
  right: 2px;
}
.toggle-text.green-text {
  color: rgb(6, 138, 240);
}
.toggle-text-num {
  width: 115px;
}
.e-col-7 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-6 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-5 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-4 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-3 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-2 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-1 .Events--box {
  min-width: 50px;
  max-width: 100%;
}
.e-col-1 {
  grid-template-columns: 1fr;
}
.e-col-2 {
  grid-template-columns: 1fr 1fr;
}
.e-col-3 {
  grid-template-columns: 1fr 1fr 1fr;
}
.e-col-4 {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}
.e-col-5 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}
.e-col-6 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
}
.e-col-7 {
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}
/*Events Ends*/

/*Strips Start*/
.strips-main {
  display: inline-block;
  width: 100%;
  padding: 20px;
}
.strips-inner {
  display: inline-block;
  width: 100%;
  padding: 20px;
  background: #fff;
  box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.06);
}
/*Strips Ends*/
.ecg--right--home {
  background-color: #f5f5f5;
  height: calc(100vh - 106px);
  padding: 1.5rem 15px 4rem 15px;
}
.patient--table {
  width: 100%;
}
.patient--table tr {
  cursor: pointer;
}
.patient--table th {
  background: #dfe2e9;
  font-weight: 600;
  color: #000;
  padding: 0 10px;
  height: 38px;
  vertical-align: middle;
  font-size: 13px;
}
.patient--table th:first-child {
  border-radius: 5px 0 0 0;
}
.patient--table th:last-child {
  border-radius: 0 5px 0 0;
}
.patient--table td {
  padding: 0 10px;
  height: 48px;
  vertical-align: middle;
  border-bottom: 1px solid #e5e7eb;
  background: #fff;
  font-size: 13px;
}
.patient--table td:first-child {
  border-left: 1px solid #e5e7eb;
}
.patient--table td:last-child {
  border-right: 1px solid #e5e7eb;
}
.patient--table tbody tr:nth-child(2n) td {
  background: #f5f5f5;
}
.patient-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 0 10px 0;
}
.patient-head h1 {
  font-size: 22px;
  color: #000;
  font-weight: 600;
}
.patient-head span {
  color: #9a9a9a;
  font-weight: 600;
  font-size: 18px;
}
.dot-red {
  display: flex;
  width: 12px;
  height: 12px;
  background: rgb(248 113 113);
  border-radius: 100%;
}
.dot-green {
  display: flex;
  width: 12px;
  height: 12px;
  background: rgb(74 222 128);
  border-radius: 100%;
}
.dot-yellow {
  display: flex;
  width: 12px;
  height: 12px;
  background: rgb(250 204 21);
  border-radius: 100%;
}
.pagination--ecg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  border: 1px solid #e5e7eb;
  border-top: none;
  padding: 0 10px 0 20px;
  color: #000;
  font-size: 14px;
  border-radius: 0 0 5px 5px;
}
.pagination--left {
  display: flex;
  align-items: center;
  gap: 20px;
}
.arrow-left {
  font-size: 30px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.43);
}
.arrow-right {
  font-size: 30px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.43);
  transform: rotate(180deg);
  margin: 6px 0 0 0;
}
.page-number {
  display: flex;
  height: 32px;
  width: 32px;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.08);
  border-radius: 100%;
  font-size: 13px;
}
.pagination--right {
  display: flex;
  align-items: center;
  gap: 15px;
  font-size: 16px;
}
.pagination--right select {
  border: 1px solid #b0bac9;
  background: transparent;
  border-radius: 3px;
  padding: 0 3px;
}
.th-sort {
  display: inline-block;
  width: 15px;
}
.th-sort svg {
  float: left;
  margin: 0px 0 -5px 3px;
  line-height: 0;
  height: 12px;
  color: rgb(156 163 175);
}
.menu--link {
  height: 48px;
  justify-content: flex-start !important;
  gap: 20px;
  height: 48px;
  background: transparent !important;
  border-radius: 0 !important;
  color: rgb(118 195 255) !important;
}
.menu--link:hover {
  background: transparent !important;
}
.menu--link span {
  color: rgb(118 195 255) !important;
  font-weight: 700;
  font-size: 16px;
}
.menu--link:first-child {
  margin: 10px 0 15px 0 !important;
}
.patient--main {
  gap: 0 !important;
}
.ecg--main.patient--main .ecg--left {
  width: 75px;
  min-width: 75px;
  display: none;
}
.rows-cols {
  display: flex;
  position: relative;
  margin: 0 0 0 0;
}
.select-boxes {
  position: absolute;
  display: inline-block;
  background: #fff;
  z-index: 100000;
  left: 0;
  top: 21px;
  border: 0.5px solid #c7cace;
  border-radius: 5px;
  padding: 10px;
}
.row-over {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  gap: 0;
  margin: 0 0 0 0;
}
.row-over span {
  display: flex;
  padding: 1.5px;
}
.row-over span p {
  display: flex;
  width: 15px;
  height: 15px;
  border: 1px solid #000;
  border-radius: 3px;
  margin: 0;
}
.row-over span.hover p {
  border: 1px solid #000;
  background: #000;
}
.events--right--click {
  position: absolute;
  background: #fff;
  border: 0.5px solid #c7cace;
  border-radius: 3px;
  left: 10px;
  top: 10px;
  z-index: 10000;
  display: inline-block;
  padding: 10px;
}
.events--right--click a {
  background: #daefff;
  font-size: 13px;
  padding: 6px 12px;
  border-radius: 5px;
  font-weight: 600;
}
.auto-fill-ryth {
  height: 150px;
  overflow: auto;
}
.events--right--click input {
  border: 1px solid #000;
  height: 35px;
  border-radius: 5px;
  margin: 0 0 15px 0;
  padding: 0 10px;
  max-width: 250px;
}
.auto-fill-ryth a {
  display: flex;
  align-items: center;
  height: 30px;
  line-height: 14px;
  font-size: 13px;
}

.ecg--right__bottom__sm__reports {
  display: inline-block !important;
  width: 100%;
  background: #f5f5f5;
  height: auto !important;
  padding: 10px;
  color: #000;
}
.reports-tabs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1px;
}
.reports-tabs a {
  background: #dfe2e9;
  border-top: 3px solid #dfe2e9;
  color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  border-radius: 8px 8px 0 0px;
  font-weight: 600;
}
.reports-tabs a.SelectedTab {
  border-top: 3px solid #76c3ff;
  background: #fff;
}
.study-panel {
  display: grid;
  grid-template-columns: 1fr;
  background: #fff;
  border-radius: 0 0 8px 8px;
  margin: 0 0 12px 0;
}
.study--inn {
  display: flex;
  align-items: center;
  gap: 41px;
  height: 40px;
  padding: 0 4rem;
}
.study-data {
  display: flex;
  align-items: center;
  gap: 5px;
}
.study-data:last-child b {
  color: #f45b5b;
}
.study-data b {
  font-weight: 600;
}
.px-9.mb-12.max-w-\[320px\] {
  max-width: 350px;
}
.px-9.mt-4.mb-2.max-w-\[360px\] {
  max-width: 400px;
}
.font-bold.text-\[26px\].mb-5 {
  margin-bottom: 0.8rem !important;
}
.hidden {
  display: none !important;
}
.grid.grid-cols-5.place-content-evenly.w-full.pl-40.mx-auto {
  padding-left: 3rem;
}
.logout {
  width: 25px !important;
  height: 25px !important;
  cursor: pointer;
}
/*login starts*/
.login--main {
  display: grid;
  grid-template-columns: 1fr 1.5fr;
  color: #000;
}
.login--right {
  height: 100vh;
  overflow: hidden;
}
.login--right img {
  width: 100%;
  height: auto;
}
.login--left {
  display: flex;
  height: 100vh;
  align-items: center;
  justify-content: center;
}
.login-left--inn {
  max-width: 453px;
}
.login--logo {
  display: flex;
  align-items: center;
  justify-content: center;
}
.login--logo img {
  width: auto;
  height: 132px !important;
}
.login-text {
  display: flex;
  justify-content: center;
  font-size: 16px;
  color: #000;
  margin: -10px 0 55px 0;
}
.login-input {
  display: inline-block;
  width: 100%;
  text-align: center;
  margin: 0 0 25px 0;
}
.label-txt {
  display: flex;
  max-width: 100%;
  margin: 0 0 10px 0;
}
.login-input input {
  width: 100%;
  height: 58px;
  padding: 0 15px;
  font-size: 16px !important;
  border-radius: 5px;
  border: 1px solid rgb(209, 213, 219);
  background-color: #fff !important;
}
.btn--login button {
  background: rgb(4 195 92);
  border: rgb(4 195 92);
  color: #fff;
  border-radius: 5px;
  height: 50px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin: 10px 0 0 0;
}
.ti-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px 0 0 0;
}
.ti-logo img {
  width: 79px;
  height: auto;
  margin: -30px 0 0 7px;
}
input {
  background-color: #fff !important;
}
/*login ends*/
.fade.modal.show {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 5000 !important;
}
.scroll-3 img {
  height: 12px;
}
.scroll-4 img {
  height: 12px;
}
.edit-icon {
  position: relative;
}
.edit-icon img {
  position: absolute;
  right: 20px;
}
.btn--prenex img {
  height: inherit;
  width: inherit;
  display: inline-block;
}
.report-images {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
}
.report-images img {
  width: 1241px;
  height: auto;
  margin: 0 auto;
}
.hr-monitor {
  display: flex;
  align-items: flex-end;
  gap: 80px;
  height: 40px;
  padding: 0 0 0 15px;
}
.monitor-child {
  display: flex;
  gap: 10px;
}
.monitor-child a {
  display: flex;
  width: 20px;
  height: 20px;
  border: 2px solid #cacaca;
  border-radius: 2px;
}
.monitor-text p {
  font-size: 13px;
  line-height: 18px;
  font-weight: 500;
  text-align: left;
  display: flex;
}
.monitor-text span {
  font-size: 11px;
  color: #555;
  line-height: 14px;
  text-align: left;
  display: flex;
}
.monitor-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.other-beats {
  background-color: #efd496;
  border: 2px solid #efd496 !important;
}
.psvc {
  background-color: #81e2d4;
  border: 2px solid #81e2d4 !important;
}
.pvc {
  background-color: #f1dee2;
  border: 2px solid #f1dee2 !important;
}
.max-hr-bac {
  background-color: #bae1ff;
  border: 2px solid #bae1ff !important;
}
.min-hr-bac {
  background-color: #ebff00;
  border: 2px solid #ebff00 !important;
}
.afib-flu-bac {
  background-color: #ffe4af;
  border: 2px solid #ffe4af !important;
}
.sinus-bac {
  background-color: #b5ffb1;
  border: 2px solid #b5ffb1 !important;
}

.single-thumb .range-slider__thumb[data-lower] {
  width: 0;
}
.single-thumb .range-slider__range {
  border-radius: 6px;
}
.range-slider-main {
  display: flex;
  align-items: center;
  min-width: 445px;
  gap: 10px;
  margin: 0 20px 0 0;
  justify-content: flex-end;
}
.range-slider-main span {
  display: flex;
  width: 70px;
  font-size: 13px;
  font-weight: 600;
}
.range-slider-main a {
  display: flex;
  width: 25px !important;
  height: 25px;
  align-items: center;
  justify-content: center;
  background: #daefff;
  border-radius: 100%;
  font-weight: 700;
  font-size: 18px;
}
.range-slider-main input {
  width: calc(100% - 150px);
}
.range-slider .range-slider__range {
  background: #daefff;
}
.range-slider .range-slider__thumb {
  background: #daefff;
}

/* === range theme and appearance === */
input[type="range"] {
  color: #daefff;
  --thumb-height: 14px;
  --track-height: 7px;
  --track-color: rgba(0, 0, 0, 0.2);
  --clip-edges: 0.125em;
}

/* === range commons === */
input[type="range"] {
  position: relative;
  background: #fff0;
  overflow: hidden;
  border: 10px;
}

/* === WebKit specific styles === */
input[type="range"],
input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: var(--thumb-height);
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-runnable-track,
input[type="range"]::-webkit-slider-thumb {
  position: relative;
  border-radius: 25px;
}

input[type="range"]::-webkit-slider-thumb {
  --thumb-radius: calc((var(--thumb-height) * 0.5) - 1px);
  --clip-top: calc((var(--thumb-height) - var(--track-height)) * 0.5 - 0.5px);
  --clip-bottom: calc(var(--thumb-height) - var(--clip-top));
  --clip-further: calc(100% + 1px);
  --box-fill: calc(-100vmax - var(--thumb-width, var(--thumb-height))) 0 0
    100vmax currentColor;

  width: var(--thumb-width, var(--thumb-height));
  background: linear-gradient(currentColor 0 0) scroll no-repeat left center /
    50% calc(var(--track-height) + 1px);
  background-color: currentColor;
  box-shadow: var(--box-fill);
  border-radius: var(--thumb-width, var(--thumb-height));

  filter: brightness(100%);
  clip-path: polygon(
    100% -1px,
    var(--clip-edges) -1px,
    0 var(--clip-top),
    -100vmax var(--clip-top),
    -100vmax var(--clip-bottom),
    0 var(--clip-bottom),
    var(--clip-edges) 100%,
    var(--clip-further) var(--clip-further)
  );
}

input[type="range"]::-webkit-slider-runnable-track {
  background: linear-gradient(var(--track-color) 0 0) scroll no-repeat center /
    100% calc(var(--track-height) + 1px);
}

/* === Firefox specific styles === */
input[type="range"],
input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb {
  appearance: none;
  transition: all ease 100ms;
  height: var(--thumb-height);
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-thumb,
input[type="range"]::-moz-range-progress {
  background: #fff0;
}

input[type="range"]::-moz-range-thumb {
  background: currentColor;
  border: 0;
  width: var(--thumb-width, var(--thumb-height));
  border-radius: var(--thumb-width, var(--thumb-height));
  cursor: grab;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  background: var(--track-color);
}

input[type="range"]::-moz-range-progress {
  appearance: none;
  background: currentColor;
}

input[type="range"]::-moz-range-track,
input[type="range"]::-moz-range-progress {
  height: calc(var(--track-height) + 1px);
  border-radius: var(--track-height);
}
/*input[type="range"]::-moz-range-track {
  background-color: #efefef;
  height: 6px;
  border-radius: 15px;
  border: 1px solid #b2b2b2;
}
input[type="range"]::-moz-range-progress {
  background-color: #5164f9;
  border-radius: 15px;
  height: 6px;
}
input[type="range"]::-moz-range-thumb {
  background-color: #5164f9;
  border: none;
  width: 14px;
  height: 14px;
}*/
.pan-click {
  margin: 0 20px 0 0;
}
.pan-click a {
  background: none;
  width: auto;
  height: auto;
}
.pan-click a img {
  width: auto;
  height: 21px;
}
.nsvaf {
  display: flex;
  align-items: center;
  gap: 7px;
  margin: 0 0 0 20px;
  color: #000;
  text-transform: uppercase;
}
.nsvaf a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 18px;
  height: 18px;
  background-color: #b5ffb1;
  border-radius: 3px;
  font-size: 12px;
  line-height: 0 !important;
  font-weight: bold;
}
.nsvaf a:nth-child(2) {
  background-color: #f6fda1;
}
.nsvaf a:nth-child(3) {
  background-color: #ff6961;
}
.nsvaf a:nth-child(4) {
  background-color: #a9fbdd;
}
.nsvaf a:nth-child(5) {
  background-color: #ffe4af;
}
/*May Design Updates Ends*/

/**Responsive Starts**/
@media (max-width: 1400px) {
  .controls--pt1 a {
    padding: 3px 5px;
  }
  .controls--pt1 a img {
    height: 14px;
    width: auto;
  }
  .controls--pt2 {
    margin: 0 5px 0 0;
  }
  .controls--pt2__ddl a {
    font-size: 12px;
  }
  .controls--pt2 {
    gap: 10px;
  }
  .controls--pt2__ddl__drop {
    min-width: 140px;
  }
  .controls--pt2__ddl__drop a {
    font-size: 11px;
    height: 30px;
  }
}
/**Responsive Ends**/
/**Loader Starts**/
.loader-overlay {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 1001;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
}
.feed-main .loader-overlay {
  display: flex;
  position: sticky;
  left: 0;
  top: 0;
  z-index: 100000;
  width: 100%;
  height: 350px;
  background-color: rgba(255, 255, 255, 0.7);
  align-items: center;
  justify-content: center;
}
